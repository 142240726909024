import "./App.css";

function App() {
  window.location.replace(
    "https://app.dosen.io?org=pepperdineuniversity&prompt=true"
  );
  return null;
}

export default App;
